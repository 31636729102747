<template>
  <div class="no_data">
    <img src="../assets/img/nodata.png" />
    <p>暂无数据</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang='scss'>
  .no_data {
    width: 100%;
    text-align: center;
    padding: 50px 0;
    color: #909399;

    img {
      width: 100px;
      height: 100px;
      margin-bottom: 15px!important;
    }
  }
</style>