import axios from './index';
const baseUrl = process.env.NODE_ENV === 'production'? '/api' : '/api';

const API = {
  // 获取图片验证码
  GetImageVerifyCode: () => axios.download(baseUrl + '/imageverifycode/getImageVerifyCode', { rad: Date.now() }),

  // 用户
  Login: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/login', data, formData, true),
  Register: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/register', data, formData, true),
  ForntUserDetail:(data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/detailUser', data, formData, true),
  OutLogin: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/logout', data, formData, true),
  UpdateEmail: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/updateEmail', data, formData, true),
  UpdatePassword: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/updatePassword', data, true),
  UpdatePhone: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/updatePhone', data, formData, true),
  PasswordValidate: (data = {}, formData) => axios.post(baseUrl + '/front/users/userFront/verifyPassword', data, formData, true),
  PhoneMessage: (data = {}, formData) => axios.post(baseUrl + '/phone/sendSms', data, formData, true),
  PhoneValidate: (data = {}, formData) => axios.post(baseUrl + '/phone/verifyPhoneAndCode', data, formData, true),
  PhoneInit: (data = {}, formData) => axios.post(baseUrl + '/phone/verifyPhone', data, formData, true),

  // 附件管理
  UploadFile: (data) => axios.post(baseUrl + '/attachments/upload', data, 2),
  DownloadFile: (data) => axios.download(baseUrl + '/attachments/download', data),
  RichUploadFile: (data) => axios.post(baseUrl + '/attachments/uploadForRichText', data, 2),
  DeleteFile: (data, formData) => axios.post(baseUrl + '/attachments/delete', data, 3, true),

  // 轮播图
  CarouselList: (data = {}, formData) => axios.post(baseUrl + '/front/slideshow/selectList', data, formData, true),

  // 用户协议 
  UserAgreement: (data = {}, formData) => axios.post(baseUrl + '/front/agreement/select', data, formData, true),

  // 测评说明
  TestDescription: (data = {}, formData) => axios.post(baseUrl + '/front/appraisal/select', data, formData, true),

  // 联系方式
  Relation: (data = {}, formData) => axios.post(baseUrl + '/front/contact/select', data, formData, true),

  // 通知资讯
  ArticleList: (data, formData) => axios.post(baseUrl + '/front/news/selectListFront', data, formData, true),
  ArticleDetail: (data = {}, formData) => axios.post(baseUrl + '/front/news/selectDetails', data, 3, true),
  TopArticleList: (data = {}, formData) => axios.post(baseUrl + '/portal/topping_content/selectList', data, true),

  // 知识库数据统计和分类
  KnowledgeBaseTotal: (data = {}, formData) => axios.post(baseUrl + '/portal/policies_regulations/selectCount', data, 3, true),
  KnowledgeBaseType: (data = {}, formData) => axios.post(baseUrl + '/portal/policies_regulations/selectFourVOS', data, true),
  KnowledgeBaseTypeOne: (data = {}, formData) => axios.post(baseUrl + '/portal/policies_regulations/selectFour', data, 3, true),

  // 政策法规
  RegulationsManagementList: (data, formData) => axios.post(baseUrl + '/portal/policies_regulations/selectPolicies', data, formData, true),
  RegulationsDetail: (data = {}, formData) => axios.post(baseUrl + '/portal/policies_regulations/selectDetail', data, true),

  // 书籍
  BookList: (data, formData) => axios.post(baseUrl + '/portal/book_management/selectBook', data, formData, true),
  BookDetail: (data = {}, formData) => axios.post(baseUrl + '/portal/book_management/selectDetailBook', data, true),

  // 标准管理
  StandardList: (data, formData) => axios.post(baseUrl + '/portal/standard/selectStandard', data, formData, true),
  StandardDetail: (data, formData) => axios.post(baseUrl + '/portal/standard/selectDetailStandard', data, formData, true),

  // 专项服务
  SpecializedServicesList: (data = {}, formData) => axios.post(baseUrl + '/portal/special_services/selectAll', data, formData, true),

  // 课程管理
  TrainingCoursesList: (data, formData) => axios.post(baseUrl + '/portal/course/selectCourse', data, formData, true),
  TrainingCoursesDetail: (data, formData) => axios.post(baseUrl + '/portal/course/selectDetailCourse', data, true),


}
export default API
