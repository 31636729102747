export const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('@/layout/Index'),
    children: [
      { // 注册
        path: '/register',
        name: 'register',
        component: () => import('@/views/register/Index'),
        meta: {
          titleName: '用户注册 - 社会团体标准化专业人员公共服务平台',
        }
      },
      { // 重置密码
        path: '/resetPassword',
        name: 'resetPassword',
        component: () => import('@/views/resetPassword/Index'),
        meta: {
          titleName: '重置密码 - 社会团体标准化专业人员公共服务平台',
        }
      },
      { // 个人中心
        path: '/personCenter',
        name: 'personCenter',
        component: () => import('@/views/personCenter/Index'),
        meta: {
          titleName: '个人中心 - 社会团体标准化专业人员公共服务平台',
          neddLogin: 1
        }
      },
      { // 首页
        path: '/home',
        name: 'home',
        component: () => import('@/views/home/Index'),
        meta: {
          titleName: '社会团体标准化专业人员公共服务平台',
          path: 'home'
        }
      },
      { // 通知资讯
        path: '/notificationInformation',
        name: 'notificationInformation',
        component: () => import('@/views/notificationInformation/Index'),
        meta: {
          titleName: '通知资讯 - 社会团体标准化专业人员公共服务平台',
          path: 'notificationInformation'
        }
      },
      { // 通知资讯
        path: '/notificationInformationDetail/:id',
        name: 'notificationInformationDetail',
        component: () => import('@/views/notificationInformation/Detail'),
        meta: {
          path: 'notificationInformation'
        }
      },
      { // 知识库
        path: '/knowledgeBase/:type?',
        name: 'knowledgeBase',
        component: () => import('@/views/knowledgeBase/Index'),
        meta: {
          path: 'knowledgeBase'
        }
      },
      { // 政策法规详情
        path: '/policiesRegulationsDetail/:id',
        name: 'policiesRegulationsDetail',
        component: () => import('@/views/knowledgeBase/PoliciesRegulationsDetail'),
        meta: {
          path: 'knowledgeBase'
        }
      },
      { // 标准详情
        path: '/standardDetail/:id',
        name: 'standardDetail',
        component: () => import('@/views/knowledgeBase/StandardDetail'),
        meta: {
          path: 'knowledgeBase'
        }
      },
      { // 书籍详情
        path: '/bookDetail/:id',
        name: 'bookDetail',
        component: () => import('@/views/knowledgeBase/BookDetail'),
        meta: {
          path: 'knowledgeBase'
        }
      },
      { // 课程详情
        path: '/courseDetail/:id',
        name: 'courseDetail',
        component: () => import('@/views/knowledgeBase/CourseDetail'),
        meta: {
          path: 'knowledgeBase'
        }
      },
      { // 测评
        path: '/testReviews',
        name: 'testReviews',
        component: () => import('@/views/testReviews/Index'),
        meta: {
          titleName: '测评 - 社会团体标准化专业人员公共服务平台',
          path: 'testReviews'
        }
      },
      { // 测评说明
        path: '/testDescription',
        name: 'testDescription',
        component: () => import('@/views/testDescription/Index'),
        meta: {
          path: 'testReviews'
        }
      },
      { // 专项服务
        path: '/specializedServices',
        name: 'specializedServices',
        component: () => import('@/views/specializedServices/Index'),
        meta: {
          titleName: '专题服务 - 社会团体标准化专业人员公共服务平台',
          path: 'specializedServices'
        }
      },
      
      { // 用户协议
        path: '/userAgreement',
        name: 'userAgreement',
        component: () => import('@/views/userAgreement/Index'),
        meta: {
          titleName: '社会团体标准化专业人员公共服务平台',
        }
      },
      
      { // 链接失效
        path: '/403',
        name: '403',
        component: () => import('@/views/LinkInvalid'),
      },
      { // 404
        path: '/:catchAll(.*)',
        name: '404',
        component: () => import('@/views/404'),
      },
    ]
  },
]
