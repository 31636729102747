import { createStore } from 'vuex'

export default createStore({
  state: {
    loginIn: 0,
    open: 0,
    userInfo: null,
    newsType: null
  },
  mutations: {
    login_type(state, data) {
      state.loginIn = data
    },
    open_type(state, data) {
      state.open = data
    },
    userInfo_type(state, data) {
      state. userInfo = { ...data }
    },
    news_type(state, data) {
      state. newsType = { ...data }
    },
  },
  actions: {
  },
  modules: {
  }
})
