import 'babel-polyfill';
import { createApp, onMounted } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import API from './require/api';
import SvgIcon from '@/components/SvgIcon.vue'

import NoData from '@/components/NoData.vue'
import VueWechatTitle from 'vue-wechat-title'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const req = require.context('./icons/svg', false,  /\.svg$/)
req.keys().map(req)

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if(error.response.status === 401 || error.response.status === 403) { //与后台约定登录失效的返回码
    router.replace('/');
  }
})

App.setup = () => {
  onMounted(() => {
    document.dispatchEvent(new Event('render-event'));
  })
}

const app = createApp(App);
app.config.globalProperties.$API = API;
app.config.globalProperties.baseUrl = '/api';
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.component('svg-icon', SvgIcon)
app.component('NoData', NoData);
app.use(store)
app.use(VueWechatTitle)
app.use(router)
app.use(ElementPlus)
app.mount('#app')
