<template>
  <router-view />
</template>

<style lang="scss">
@import './assets/css/common.scss';
#app {
  font-family: PingFang SC, PingFang SC-Semibold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9fb;
  min-width: 1200px;
  min-height: 100vh;
}

</style>
